/*!
 * Bootstrap v3.3.7 (http://getbootstrap.com)
 * Copyright 2011-2016 Twitter, Inc.
 * Licensed under MIT (https://github.com/twbs/bootstrap/blob/master/LICENSE)
 */

// Core variables and mixins
@import "includes/font-roboto.less";
@import "includes/variables.less";
@import "includes/bootstrap/mixins.less";

// Reset and dependencies
@import "includes/bootstrap/normalize.less";
@import "includes/bootstrap/print.less";
@import "includes/bootstrap/glyphicons.less";

// Core CSS
@import "includes/bootstrap/scaffolding.less";
@import "includes/bootstrap/type.less";
@import "includes/bootstrap/code.less";
@import "includes/bootstrap/grid.less";
@import "includes/bootstrap/tables.less";
@import "includes/bootstrap/forms.less";
@import "includes/bootstrap/buttons.less";

// Components
@import "includes/bootstrap/component-animations.less";
@import "includes/bootstrap/dropdowns.less";
@import "includes/bootstrap/button-groups.less";
@import "includes/bootstrap/input-groups.less";
@import "includes/bootstrap/navs.less";
@import "includes/bootstrap/navbar.less";
@import "includes/bootstrap/breadcrumbs.less";
@import "includes/bootstrap/pagination.less";
@import "includes/bootstrap/pager.less";
@import "includes/bootstrap/labels.less";
@import "includes/bootstrap/badges.less";
@import "includes/bootstrap/jumbotron.less";
@import "includes/bootstrap/thumbnails.less";
@import "includes/bootstrap/alerts.less";
@import "includes/bootstrap/progress-bars.less";
@import "includes/bootstrap/media.less";
@import "includes/bootstrap/list-group.less";
@import "includes/bootstrap/panels.less";
@import "includes/bootstrap/responsive-embed.less";
@import "includes/bootstrap/wells.less";
@import "includes/bootstrap/close.less";

// Components w/ JavaScript
@import "includes/bootstrap/modals.less";
@import "includes/bootstrap/tooltip.less";
@import "includes/bootstrap/popovers.less";
@import "includes/bootstrap/carousel.less";

// Utility classes
@import "includes/bootstrap/utilities.less";
@import "includes/bootstrap/responsive-utilities.less";

a:hover, a:focus {
    text-decoration: none;
}

.text-white {
    color: rgb(255, 255, 255);
}
.text-accent {
    color: @color-primary-accent;
}

.no-gutter {
    padding: 0px;
}

.form-control {
    border: 0px;
}

label {
  font-weight: normal;
}

.form-control, .form-control:focus {
    border-bottom: 1px solid @color-primary-primary;
    box-shadow: none;
    margin-bottom: 20px;
}

div.form-group:hover {
    label {
        color: lighten(@color-primary-primary, 30%);
    }
    input[type="text"], input[type="password"], input[type="search"] {
        border-bottom: 1px solid lighten(@color-primary-primary, 30%);
        color: lighten(@color-primary-primary, 30%);
    }
}

button > i {
    margin-right: 10px;
    color: lighten(@color-primary-accent, 30%);
}