@font-face {
    font-family: 'roboto';
    src: url('/fonts/Roboto-Regular-webfont.eot');
    src: url('/fonts/Roboto-Regular-webfont.eot?#iefix') format('embedded-opentype'),
         url('/fonts/Roboto-Regular-webfont.woff') format('woff'),
         url('/fonts/Roboto-Regular-webfont.ttf') format('truetype'),
         url('/fonts/Roboto-Regular-webfont.svg#robotoregular') format('svg');
    font-weight: normal;
    font-style: normal;
}
